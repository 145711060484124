import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import { FaExternalLinkAlt } from "react-icons/fa"

import * as style from "./project.module.scss"

const Project = ({ data }) => {
  const project = data.strapiProject

//   const seo = {
//     metaTitle: article.title,
//     metaDescription: article.description,
//     shareImage: article.cover,
//   }

  return (
    <main className="mt-8">
      <section>
        <Link className={style.link} to={`/work`}>Back</Link>
        <h1>{project.Name}</h1>
      </section>
      <section>
        <a href={project.Link} className={style.extLink} target="_blank">Visit the website <FaExternalLinkAlt className={style.linkIcon}/></a>
      </section>
      <section>
        <span className={"subheader"}>Role</span>
        <h4 className={"header"}>{project.Role}</h4>
        <span className={"subheader"}>Timeframe</span>
        <h4 className={"header"}>{project.Timeframe}</h4>
        {/* {project.technologies.map(tech => (
          <div>
            {tech.Technology}
          </div>
        ))} */}
      </section>
      <section>
        {project.Screenshots.map(s => (
          <GatsbyImage className={style.image} image={getImage(s.localFile)} alt={s.alternativeText}/>
        ))}
      </section>
    </main>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiProject(Slug: { eq: $slug }) {
      Name
      Slug
      Role
      Timeframe
      Link
      technologies {
        Technology
      }
      Screenshots {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`

export default Project